import { useMemo, useState, useReducer } from 'react';
import targetValue from 'utils/targetValue';

export const useCertModalControls = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState({});

  const handlers = useMemo(
    () => ({
      open: job => {
        setData({
          jobId: job.id,
          isFullEdit: job.isFullEdit,
        });
        setIsOpen(true);
      },
      close: () => {
        setIsOpen(false);
        setData({});
      },
    }),
    [],
  );

  return {
    isOpen,
    data,
    ...handlers,
  };
};

export const useEditJobModalControls = () => {
  const [data, setData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const handlers = useMemo(
    () => ({
      open: job => {
        setData({
          jobId: job.id,
          operationType: job.operation_type
        });
        setIsOpen(true);
      },
      close: () => {
        setIsOpen(false);
        setData({});
      },
    }),
    [],
  );

  return {
    isOpen,
    data,
    ...handlers,
  };
};

const JobsListFilterReducer = (state, action) => {
  switch (action.type) {
    case 'set_filter':
      return {
        ...state,
        page: 1,
        [action.name]: action.value,
      };
    case 'toggle_draft_job':
      return {
        ...state,
        isDraftJob: !state.isDraftJob,
      };
    default:
      return state;
  }
}

const initialFilter = {
  id: '',
  isDraftJob: false,
  jobType: -1,
  name: '',
  sort: 'id',
  status: 0,
  page: 1,
  pageSize: 10
}

export const useJobsListFilters = () => {
  const [state, dispatch] = useReducer(JobsListFilterReducer, initialFilter);

  const handlers = {
    setFilter: name => e =>
      dispatch({
        type: 'set_filter',
        name,
        value: targetValue(e)
      }),
    setStatus: value =>
      dispatch({
        type: 'set_filter',
        name: 'status',
        value
      }),
    setIsDraftJob: value =>
      dispatch({
        type: 'set_filter',
        name: 'isDraftJob',
        value
      }),
    setPage: value =>
      dispatch({
        type: 'set_filter',
        name: 'page',
        value
      }),
    setPageSize: value =>
      dispatch({
        type: 'set_filter',
        name: 'pageSize',
        value
      })
  };

  return {
    handlers,
    state,
  };
};
