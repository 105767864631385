import React, { useState } from 'react';

import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Authorize from 'components/common/Authorize';
import Header from 'components/common/Header';

import { useDebounce } from 'helpers/hooks';

import { usePaginatedJobs } from 'api/v2/jobs';
import { useUsers } from 'api/v2/users'

import Jobs from './Jobs';
import Filter from './Filters';
import AddJob from './AddJobModal';
import EditJob from './EditJobModal';
import CertificatesModal from './CertificatesModal';
// import QuickBooksModal from './QuickBooksModal';
import { useCertModalControls, useEditJobModalControls, useJobsListFilters } from './hooks';

const JobList = ({
  customerId,
  initQuickBooksModal,
}) => {
  const [showAddJob, turnModalAddJob] = useState(false);
  const [showQuickBooks, turnModalQuickBooks] = useState(false);

  const { handlers: filterHandlers, state: filterState } = useJobsListFilters();
  const debouncedFilters = useDebounce(filterState, 500);
  const filters = {
    ...filterState,
    id: debouncedFilters.id,
    name: debouncedFilters.name,
    isDraftJob: Number(filterState.isDraftJob),
    customerId
  };
  const { data: jobsData, isLoading } = usePaginatedJobs(filters);
  const { data: users } = useUsers();

  const { setFilter, setStatus, setIsDraftJob, setPage, setPageSize } = filterHandlers;
  const editModal = useEditJobModalControls();
  const certModal = useCertModalControls();

  return (
    <>
      <Header title="View Jobs">
        <Authorize
          abilityPermissions={[
            {
              resource: 'Jobs',
              permissions: ['create'],
            },
          ]}>
          <Button
            onClick={() => turnModalAddJob(true)}
            inverse
            testSelector="jobs-list_add-job_btn"
            style={{ float: 'right' }}>
            <Icon className="icon--margin-right" icon="plus-square" />
            Add Job
          </Button>
        </Authorize>
      </Header>

      <Authorize
        abilityPermissions={[
          { resource: 'Data-van-info', permissions: ['read'] },
        ]}>
        <Filter
          setFilter={setFilter}
          setPage={setPage}
          setPageSize={setPageSize}
          filter={filterState}
          setStatus={setStatus}
          setIsDraftJob={setIsDraftJob}
          totalPages={jobsData?.totalPages ?? 0}
        />
        <Jobs
          users={users}
          openEditJobModal={job => editModal.open(job)}
          jobs={jobsData?.jobs ?? []}
          isLoading={isLoading}
          openCertificates={certModal.open}
          openQuickBooks={data => {
            initQuickBooksModal(data);
            turnModalQuickBooks(true);
          }}
        />
      </Authorize>

      <AddJob
        showAddJob={showAddJob}
        closeAddJobModal={() => {
          turnModalAddJob(false);
        }}
      />
      <EditJob
        jobId={editModal.data.jobId}
        operationType={editModal.data.operationType}
        showEditJob={editModal.isOpen}
        closeEditJobModal={() => editModal.close()}
      />
      <CertificatesModal
        jobId={certModal.data.jobId}
        isFullEdit={certModal.data.isFullEdit}
        showModal={certModal.isOpen}
        closeModal={certModal.close}
      />
      {/* Temporary commented, it may to bring back later (will need to refactor if so)
      <QuickBooksModal
        showQuickBooks={showQuickBooks}
        closeQuickBooks={() => {
          turnModalQuickBooks(false);
        }}
      /> */}
    </>
  );
};

export default JobList;
