import React, { useState, useMemo } from 'react';
import { Modal, Row } from 'react-bootstrap';

import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import DatePicker from 'components/shared/DatePicker';

import { useJobDetails } from 'api/v2/jobs';
import { isDateFormatValid } from 'utils/datePickersFunctions';
import Loader from 'components/shared/Loader';
import { useAddOrder } from 'api/v2/orders';
import { driverEquipmentType } from 'config/constants';
import StagingSiteSelect from './StagingSiteSelect';
import {
  useAddOrderForm,
  useDriverOptions,
  usePoCommodities,
  useStageOptions,
} from './hooks';
import { formatAddOrderInfo, getCalculatedPickUp } from './helpers';

const AddOrder = ({ jobId, showAddOrder, closeAddOrderModal }) => {
  const { data: jobDetails, isLoading } = useJobDetails({ jobId });
  const { mutate: addOrders, isPending } = useAddOrder({ jobId });
  const { details } = jobDetails;

  const [isDateAlert, turnDateAlert] = useState(false);
  const [isCertRequired, setIsCertRequired] = useState(false);

  const isWaterJob = useMemo(() => details?.operationType === 12, [
    details?.operationType,
  ]);

  const { data: formData, isValid, handlers } = useAddOrderForm({
    showModal: showAddOrder,
    stagingSite: details?.stagingSite,
    isWaterJob
  });
  const poCommodities = usePoCommodities(jobId);
  const driverOptions = useDriverOptions({ jobId, po: formData.po });
  const stageOptions = useStageOptions(jobId);
  // If the job only has one equipment type, PO's don't have equipment attached
  const isContainerized = useMemo(() =>
    formData?.po?.equipment === driverEquipmentType.CONTAINERIZED ||
    (details?.equipments?.length === 1 && details?.equipments?.[0] === driverEquipmentType.CONTAINERIZED),
    [formData?.po?.equipment, details.equipments]
  );

  const handleStartDateBlur = async () => {
    if (formData.po.originId && formData.startDate) {
      getCalculatedPickUp({
        unloadAppointment: formData.startDate,
        originId: Number(formData.po.originId),
        destinationId: Number(formData.po.destinationId),
      })
        .then(handlers.valueChange('loadDate'))
        .catch(() => { });
    }
  };

  const handleWarning = async interstateCertRequired => {
    const certRequired = Boolean(interstateCertRequired);
    setIsCertRequired(certRequired);
  };

  const handleWellsite = async wellPo => {
    if (wellPo.originId && wellPo.destinationId) {
      getCalculatedPickUp({
        unloadAppointment: formData.startDate,
        originId: Number(wellPo.originId),
        destinationId: Number(wellPo.destinationId),
      })
        .then(handlers.valueChange('loadDate'))
        .catch(() => { });
    }
  };

  const handlePo = po => {
    handlers.valueChange('po')(po);
    handleWellsite(po);
    handleWarning(po.interstateCertRequired);
  };

  const handleClose = () => {
    closeAddOrderModal();
    handlers.clear();
  };

  const handleSubmit = e => {
    e.preventDefault();
    const data = formatAddOrderInfo(formData, jobDetails, poCommodities);
    if (data) {
      addOrders(data, {
        onSettled: handleClose,
      });
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Modal
      show={showAddOrder}
      fullscreen="sm-down"
      onHide={handleClose}>
      <Modal.Header>
        <h3>Add New Order</h3>
      </Modal.Header>
      <form onSubmit={handleSubmit} autoComplete="off">
        <Modal.Body>
          <Row>
            {isDateAlert && (
              <div className="nested-no-outer-gutter" style={{ color: 'red' }}>
                <p>* Incorrect date format</p>
              </div>
            )}
            <div className="form-group has-feedback col-md-6">
              <DatePicker
                label="Deliver Appointment"
                timeFormat="hh:mm A"
                required
                onBlur={handleStartDateBlur}
                value={formData.startDate}
                onChange={e => {
                  isDateFormatValid(e, turnDateAlert);
                  handlers.valueChange('startDate')(e);
                }}
                testSelector="job-details_add-order_deliver-appt_dp"
              />
            </div>
            {!isWaterJob && (
              <div className="form-group has-feedback col-md-6">
                <DatePicker
                  label="Pick Up Appointment"
                  timeFormat="hh:mm A"
                  required
                  value={formData.loadDate}
                  onChange={e => {
                    isDateFormatValid(e, turnDateAlert);
                    handlers.valueChange('loadDate')(e);
                  }}
                  disabled={!formData.po.poNo}
                  testSelector="job-details_add-order_pickup-appt_dp"
                />
              </div>
            )}
            {!isWaterJob && (
              <>
                <div className="form-group has-feedback col-md-6">
                  {isCertRequired && (
                    <div style={{ color: 'rgba(239, 61, 61, 0.483)' }}>
                      *Interstate Cert Required
                    </div>
                  )}
                  <Select
                    placeholder="PO / Commodity / Location"
                    onChange={handlePo}
                    required
                    value={poCommodities.find(
                      item => item.value === formData.po.poNo,
                    )}
                    options={poCommodities}
                    testSelector="job-details_add-order_po_select"
                  />
                </div>
                <div className="form-group has-feedback col-md-6">
                  <Select
                    placeholder="Choose stage"
                    onChange={handlers.valueChange('stage')}
                    value={stageOptions.find(
                      item => item.value === formData.stage || null,
                    )}
                    options={stageOptions}
                    testSelector="job-details_add-order_stage_select"
                  />
                </div>
              </>
            )}
            <div className="form-group col-md-6">
              <Input
                type="number"
                onChange={handlers.valueChange('count')}
                value={formData.count}
                label="Amount of orders"
                min="1"
                max="100"
                required
                testSelector="job-details_add-order_amount_input"
              />
            </div>
            <div className="form-group col-md-6">
              <Input
                onChange={handlers.valueChange('customerOrderId')}
                value={formData.customerOrderId}
                label="Customer order ID"
                testSelector="job-details_add-order_customer-id_input"
              />
            </div>
            {details.stagingSite && (
              <div className="form-group col-md-6">
                <StagingSiteSelect
                  onChange={handlers.valueChange('stagingSite')}
                  jobStagingSite={details.stagingSite}
                  stagingSite={formData.stagingSite}
                />
              </div>
            )}
            <div className="form-group has-feedback col-md-6">
              <Input
                type="checkbox"
                isChecked={formData.autoAssign}
                onChange={handlers.valueChange('autoAssign')}
                label="Auto-assign Driver"
                testSelector="job-details_add-order_assign-driver_input"
                disabled={!isWaterJob ? !formData.po.poNo : false}
              />

              {!formData.autoAssign && (
                <Select
                  placeholder="Choose driver"
                  onChange={handlers.valueChange('driverId')}
                  required
                  value={driverOptions.find(
                    item => item.value === formData.driverId || null,
                  )}
                  options={driverOptions}
                  isDisabled={!isWaterJob ? !formData.po.poNo : false}
                  testSelector="job-details_add-order_driver_select"
                />
              )}
              {isContainerized && !formData.autoAssign && (
                <div
                  style={{ color: 'red' }}>
                  <p>
                    This order requires a driver to have a container before they can be assigned to it.
                  </p>
                </div>
              )}
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleClose}
            colour="white"
            testSelector="job-details_add-order_close_btn">
            Close
          </Button>
          <Button
            type="submit"
            disabled={!isValid || isDateAlert || isPending}
            testSelector="job-details_add-order_save_btn">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
export default AddOrder;
