import React, { useMemo, useState } from 'react';

import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Select from 'components/shared/Select';
import ToolTip from 'components/shared/ToolTip';
import { isInArray } from 'components/views/Home/HomePage/helpers';
import useHomeState from 'components/views/Home/hooks/useHomeState';
import { isValidFilterType } from 'components/views/Home/HomeFilters/helpers';

import operationType from 'utils/operationTypes';
import formatString from 'utils/formatString';
import { useSCMJobs } from 'api/v2/home';

const HomeFilters = ({ filters, applyFilters = () => {} }) => {
  const { data: mapJobs } = useSCMJobs();

  const [filterVisibility, setFilterVisibility] = useState(false);
  const [checkbox, setCheckbox] = useState({});
  const { onlyAlerts } = useHomeState();

  const activeFilters = useMemo(
    () => Object.keys(filters).filter(key => filters[key]),
    [filters],
  );

  const removeFilter = type => {
    if (isValidFilterType(type)) {
      setCheckbox({
        ...checkbox,
        [type]: !checkbox[type],
      });
      applyFilters({
        ...filters,
        [type]: undefined,
      });
    } else {
      applyFilters({
        ...filters,
        [type]: undefined,
      });
    }
  };

  const removeCheckboxFilter = type => {
    applyFilters({
      ...filters,
      [type]: '',
    });
  };

  const addFilter = (type, value) => {
    const newFilters = {
      ...filters,
      [type]: value,
    };
    applyFilters(newFilters);
  };

  const clearAllFilters = () => {
    activeFilters.forEach(type => {
      if (!isValidFilterType(type)) {
        removeFilter(type);
        return;
      }
      removeCheckboxFilter(type);
    });
    applyFilters({});
  };
  const jobIdAndName = useMemo(
    () =>
      mapJobs.reduce((array, job) => {
        if (job.id && job.name && !isInArray(array, job.id)) {
          array.push({
            value: parseInt(job.id, 10),
            label: `#${job.id} | ${job.name}`,
          });
        }
        return array;
      }, []),
    [mapJobs],
  );

  const collectedSandAndWellLocation = useMemo(
    () =>
      mapJobs.reduce((array, item) => {
        if (
          item.wellSite &&
          (!onlyAlerts || (onlyAlerts && item.wellSite.alerts))
        ) {
          array.push({
            value: parseInt(item.wellSite.id, 10),
            label: `#${item.wellSite.id} | ${item.wellSite.name}`,
          });
        }

        if (
          item.waterProductionSite &&
          (!onlyAlerts || (onlyAlerts && item.waterProductionSite.alerts))
        ) {
          array.push({
            value: parseInt(item.waterProductionSite.id, 10),
            label: `#${item.waterProductionSite.id} | ${
              item.waterProductionSite.name
            }`,
          });
        }

        if (item.orders && item.orders.length) {
          item.orders.forEach(order => {
            if (
              order.sandSite &&
              (!onlyAlerts || (onlyAlerts && order.sandSite.alerts))
            ) {
              array.push({
                value: parseInt(order.sandSite.id, 10),
                label: `#${order.sandSite.id} | ${order.sandSite.name}`,
              });
            }
            if (
              order.waterDisposalSite &&
              (!onlyAlerts || (onlyAlerts && order.waterDisposalSite.alerts))
            ) {
              array.push({
                value: parseInt(order.waterDisposalSite.id, 10),
                label: `#${order.waterDisposalSite.id} | ${
                  order.waterDisposalSite.name
                }`,
              });
            }
          });
        }
        return array;
      }, []),
    [mapJobs, onlyAlerts],
  );

  const locationIdAndName = useMemo(
    () =>
      [...new Set(collectedSandAndWellLocation.map(job => job.value))]
        .filter(item => item != null && item !== 0)
        .map(id => ({
          value: id,
          label: collectedSandAndWellLocation.find(item => item.value === id)
            .label,
        })),
    [collectedSandAndWellLocation],
  );

  const districtIdAndName = useMemo(
    () =>
      mapJobs.reduce((array, order) => {
        if (order.districts) {
          order.districts.forEach(district => {
            if (!isInArray(array, district.id)) {
              array.push({
                value: Number(district.id),
                label: `#${district.id} | ${district.name}`,
              });
            }
          });
        }
        return array;
      }, []),
    [mapJobs],
  );

  const jobTypes = useMemo(
    () =>
      [...new Set(mapJobs.map(order => order.operationType))].map(id => ({
        value: parseInt(id, 10),
        label:
          operationType[
            mapJobs.find(item => item.operationType === id).operationType
          ],
      })),
    [mapJobs],
  );

  return (
    <>
      <div className="toggle-container">
        <ToolTip
          title={`${filterVisibility ? 'Hide' : 'Show'} Filters`}
          placement="left">
          <Button
            theme="small"
            className={`filter-toggle button--small--square ${(filterVisibility &&
              'active') ||
              ''}`}
            onClick={() => setFilterVisibility(!filterVisibility)}
            inverse={filterVisibility}
            testSelector="home_filters_toggle_btn">
            <Icon icon="filter" data-testid="filter-icon" />
          </Button>
        </ToolTip>
      </div>

      <div className="home-filters">
        {activeFilters.length > 0 && (
          <div className="filters__active">
            {activeFilters.map(filter => (
              <span className="label label--filter" key={filter}>
                {formatString(filter, 't')}
                <Icon icon="close" onClick={() => removeFilter(filter)} />
              </span>
            ))}
            <Button
              theme="small"
              onClick={() => clearAllFilters()}
              testSelector="home_filters_clear-all_btn">
              Clear All
            </Button>
          </div>
        )}

        <div
          data-testid="home-main-filter-container"
          className={`home-filters-container ${
            filterVisibility ? 'open' : ''
          }`}>
          <div
            className="home-filters-container__bottom-row"
            data-testid="filters-container">
            <Select
              placeholder="Job ID / Name"
              options={jobIdAndName}
              onChange={item => addFilter('jobId', item.value)}
              value={
                jobIdAndName.find(job => filters.jobId === job.value) || null
              }
              testSelector="home_filters_job_select"
            />
            <Select
              placeholder="Location ID / Name"
              options={locationIdAndName}
              onChange={item => addFilter('locationId', item.value)}
              value={
                locationIdAndName.find(
                  location => filters.locationId === location.value,
                ) || null
              }
              testSelector="home_filters_location_select"
            />
            <Select
              placeholder="District ID / Name"
              options={districtIdAndName}
              onChange={item => addFilter('districtId', item.value)}
              value={
                districtIdAndName.find(
                  district => filters.districtId === district.value,
                ) || null
              }
              testSelector="home_filters_district_select"
            />
            <Select
              placeholder="Job Type"
              options={jobTypes}
              onChange={item => addFilter('jobType', item.value)}
              value={
                jobTypes.find(jobType => filters.jobType === jobType.value) ||
                null
              }
              testSelector="home_filters_job-type_select"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default HomeFilters;
