import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';

import {
  addPurchaseOrder,
  updatePurchaseOrder,
  getPurchaseOrdersByJob,
  getPurchaseOrders,
  getPurchaseOrderOrders,
  updateUnit
} from './queries';

export const useJobPurchaseOrders = (jobId) =>
  useQuery({
    queryKey: ['jobs', jobId, 'purchase-orders'],
    queryFn: () => getPurchaseOrdersByJob(jobId),
    placeholderData: [],
  });

export const usePurchaseOrders = ({ customerId, jobId, sandSiteId, isActive, enabled, jobIds }) =>
  useQuery({
    queryKey: ['purchase-orders', { customerId, jobId, sandSiteId, isActive }],
    queryFn: () => getPurchaseOrders({ customerId, jobId, sandSiteId, isActive: Number(isActive), jobIds }),
    placeholderData: [],
    refetchOnMount: false,
    enabled
  });

export const usePurchaseOrderOrders = (poId) =>{
  return useQuery({
    queryKey: ['purchase-order-orders', poId],
    queryFn: () => getPurchaseOrderOrders({ poId }),
    enabled: !!poId,
    placeholderData: [],
  });
}

export const useAddPurchaseOrder = (jobId, isWater = false) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['jobs', jobId],
    mutationFn: newPo => addPurchaseOrder({ jobId, newPo }),
    onSuccess: () => {
      swal(`Successfully Added ${isWater ? 'Unit' : 'PO'}`, '', 'success');
      queryClient.invalidateQueries({ queryKey: ['purchase-orders'] });
      queryClient.invalidateQueries({ queryKey: ['po-schedules'] });
      queryClient.invalidateQueries({ queryKey: ['jobs', jobId] });
      queryClient.invalidateQueries({
        queryKey: ['jobs', Number(jobId), 'details']
      });      
    },
    onError: error =>
      swal(`Unable to Add ${isWater ? 'Unit' : 'PO'}`, error.response?.data?.message, 'error'),
  });
};

export const useUpdatePurchaseOrder = ({ poId, jobId }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['purchase-orders', poId],
    mutationFn: newPo => updatePurchaseOrder({ poId, newPo }),
    onSuccess: () => {
      swal('Successfully Updated PO', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['purchase-orders'] });
      queryClient.invalidateQueries({ queryKey: ['po-schedules', poId] });
      queryClient.invalidateQueries({ queryKey: ['jobs', jobId] });
    },
    onError: error =>
      swal('Unable to Update PO', error.response?.data?.message, 'error'),
  });
};

export const useUpdateUnits = ({jobId, poNo, handleClose}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['purchase-orders', poNo],
    mutationFn: newUnit => updateUnit({ poNo, newUnit }),
    onSuccess: () => {
      swal('Successfully Updated Unit', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['purchase-orders'] });
      queryClient.invalidateQueries({ queryKey: ['po-schedules', poNo] });
      queryClient.invalidateQueries({ queryKey: ['jobs', jobId] });
    },
    onError: error => {
      swal('Unable to Update Unit', error.response?.data?.message, 'error');
    },
    onSettled: () => {
      handleClose();
    }
  });
};

