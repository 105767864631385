import React, { useState, useMemo } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import Authorize, { authorize } from 'components/common/Authorize';
import authService from 'services/auth/service';
import { isDateFormatValid } from 'utils/datePickersFunctions';

import Button from 'components/shared/Button';
import Input, { Textarea } from 'components/shared/Input';
import Select from 'components/shared/Select';
import Loader from 'components/shared/Loader';
import DatePicker from 'components/shared/DatePicker';

import { useCustomerLocations } from 'api/v2/locations';
import { useEditJob } from 'api/v2/jobs';
import { useCustomerCommodities } from 'api/v2/commodities';
import JobDesign from './JobDesign';
import StorageConfig from './OnsiteStorageConfig';
import {
  useBillingProfileOptions,
  useCertificateOptions,
  useDistrictOptions,
  useEditJobForm,
  useEquipmentOptions,
  useJobEditDetails,
  useLocationOptions,
  useOperatorOptions,
  useTagOptions,
  useTicketOptions,
} from './hooks';
import { billingProfileDisabled, formatEditData } from './helpers';


const EditJob = ({ jobId, showEditJob, closeEditJobModal }) => {
  const { data: jobData, isLoading } = useJobEditDetails({ jobId });
  const { operationType, customerId, isDraftJob } = jobData;
  const { mutate: editJob, isPending } = useEditJob({ jobId });
  const isWaterJob = jobData?.operationType === 12;

  const defaultValue = useMemo(
    () => ({
      ...jobData,
      tags: (jobData?.tags ?? []).map(tag => ({
        label: tag.name,
        value: tag.id,
        type: tag.type,
      })),
    }),
    [jobData],
  );
  const { data: formData, isValid, handlers } = useEditJobForm({
    defaultValue,
    showModal: showEditJob,
  });
  const { data: customerCommodities } = useCustomerCommodities(
    jobData?.customerId,
  );

  const [isDateAlert, turnDateAlert] = useState(false);

  const tagOptions = useTagOptions(formData.tags);
  const stagingSiteOptions = useLocationOptions(4);
  const equipmentOptions = useEquipmentOptions();
  const ticketOptions = useTicketOptions();
  const districtOptions = useDistrictOptions();
  const certificateOptions = useCertificateOptions({ jobId });
  const operatorOptions = useOperatorOptions(customerId);
  const billingProfileOptions = useBillingProfileOptions(customerId);

  const { data: wellSites } = useCustomerLocations(customerId);
  const wellsiteList = useMemo(
    () =>
      (wellSites || []).map(item => ({
        value: item.id,
        label: item.name,
      })),
    [wellSites],
  );

  const editTheBillingProfileDisabled = billingProfileDisabled(
    customerId,
    isDraftJob,
    jobData?.operators,
  );
  const isAdditionalOperatorsDisabled = !(
    authService.getUser().role === 6 ||
    Number(authService.getCompany()) === Number(customerId)
  );

  const handleClose = () => {
    closeEditJobModal();
    handlers.clear();
  };

  const handleSubmit = e => {
    e.preventDefault();
    editJob(
      formatEditData({
        ...formData,
        // Nota Bene: The testSelector attribute is used to handle multiple submit types
        isADraftSave: e?.nativeEvent?.submitter?.name === 'save-draft',
        tags: formData.tags.map(tag => tag.value),
      }),
      {
        onSuccess: handleClose,
      },
    );
  };

  return (
    <Modal className="modal-container" show={showEditJob} onHide={handleClose}>
      <Modal.Header>
        <h3>Edit Job</h3>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          {isLoading ? (
            <Loader />
          ) : (
            <Row>
              <div className="form-group has-feedback col-md-12">
                <Input
                  onChange={handlers.valueChange('name')}
                  value={formData.name}
                  label="Name"
                  required
                  testSelector="jobs_edit_name_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={handlers.valueChange('customerJobId')}
                  value={formData.customerJobId}
                  label="Customer Job Id"
                  testSelector="jobs_edit_customer-job-id"
                />
              </div>
              {operationType !== 12 && (
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e =>
                    handlers.valueChange('navId')(
                      e.target.value.replace(/\s/g, ''),
                    )
                  }
                  value={formData.navId}
                  label="Nav Id"
                  testSelector="jobs_edit_customer-nav-id"
                />
              </div>
              )}
              {operationType !== 12 && (
                <div className="form-group has-feedback col-md-6">
                  <Input
                    onChange={handlers.valueChange('customerProjectId')}
                    value={formData.customerProjectId}
                    label="Customer Project ID"
                    testSelector="jobs_add_job_customer-project-id_input"
                  />
                </div>
              )}
              {isDateAlert && (
                <div
                  className="nested-no-outer-gutter"
                  style={{ color: 'red' }}>
                  <p>* Incorrect date format</p>
                </div>
              )}
               {operationType !== 12 && (
                <Col lg={12} md={12} sm={12}>
                  {new Date(formData.endDate).getTime() <=
                    new Date(formData.startDate).getTime() && (
                    <div
                      className="nested-no-outer-gutter"
                      style={{ color: 'red' }}>
                      <p>* End Date must be later than Start Date</p>
                    </div>
                  )}
                </Col>
              )}
              <div className="form-group has-feedback col-md-6">
                <DatePicker
                  value={formData.startDate}
                  onChange={e => {
                    isDateFormatValid(e, turnDateAlert);
                    handlers.valueChange('startDate')(e);
                  }}
                  className="form-input__calendar"
                  timeFormat="hh:mm A"
                  label="Start Date"
                  testSelector="jobs-list_edit-modal_start-date_dp"
                />
              </div>
              {operationType !== 12 && (
              <div className="form-group has-feedback col-md-6">
                <DatePicker
                  value={formData.endDate}
                  onChange={e => {
                    isDateFormatValid(e, turnDateAlert);
                    handlers.valueChange('endDate')(e);
                  }}
                  isValidDate={current =>
                    current.isSameOrAfter(formData.startDate)
                  }
                  timeFormat="hh:mm A"
                  className="form-input__calendar"
                  label="End Date"
                  testSelector="jobs-list_edit-modal_end-date_dp"
                />
              </div>
              )}
              {operationType !== 12 && (
                <>
                  <div className="form-group has-feedback col-md-6">
                    <Input
                      type="number"
                      onChange={handlers.valueChange('pumpTime')}
                      value={formData.pumpTime}
                      label="Pump time (hours)"
                      required
                      testSelector="jobs_edit_pump-time_input"
                    />
                  </div>
                  <div className="form-group has-feedback col-md-6">
                    <Input
                      type="number"
                      onChange={handlers.valueChange('wirelineTime')}
                      value={formData.wirelineTime}
                      label="Wireline time (hours)"
                      required
                      testSelector="jobs_edit_wireline-time_input"
                    />
                  </div>
                </>
              )}
              {operationType !== 12 && (
                <div className="form-group has-feedback col-md-6">
                  <Input
                    type="number"
                    onChange={handlers.valueChange('numberOfStages')}
                    value={formData.numberOfStages}
                    label="Number of stages"
                    required
                    testSelector="jobs_edit_stages-number_input"
                  />
                </div>
              )}
              <div className="form-group has-feedback col-md-6">
                <Select
                  isMulti
                  required
                  closeMenuOnSelect={false}
                  onChange={handlers.valueChange('certificates')}
                  options={certificateOptions}
                  placeholder="Certificates"
                  value={formData.certificates}
                  testSelector="jobs_edit_certificates_select"
                />
              </div>
                <div className="form-group has-feedback col-md-6">
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    onChange={selected =>
                      handlers.valueChange('equipment')(
                        selected.map(s => s.value),
                      )
                    }
                    options={equipmentOptions}
                    placeholder="Equipment"
                    value={equipmentOptions.filter(er =>
                      formData.equipment?.includes(er.value),
                    )}
                    required
                    testSelector="jobs_edit_equipment_select"
                  />
                </div>
              <div className="form-group has-feedback col-md-6">
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  required
                  onChange={handlers.valueChange('districtIds')}
                  options={districtOptions}
                  placeholder="Districts"
                  value={formData.districtIds}
                  testSelector="jobs_edit_districts_select"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Select
                  isMulti
                  required
                  closeMenuOnSelect={false}
                  onChange={handlers.valueChange('sandTicketDesign')}
                  options={ticketOptions}
                  placeholder="Ticket types"
                  value={formData.sandTicketDesign}
                  testSelector="jobs_edit_ticket_types_select"
                />
                {formData.sandTicketDesign &&
                  formData.sandTicketDesign.length === 0 && (
                    <small style={{ color: 'rgba(239, 61, 61, 0.483)' }}>
                      * Need to select ticket type{' '}
                    </small>
                  )}
                <Select
                  placeholder="Billing Profile"
                  onChange={e =>
                    handlers.valueChange('billingProfileId')(e.value)
                  }
                  value={
                    Boolean(formData.billingProfileId) &&
                    billingProfileOptions.find(
                      k => k.value === formData.billingProfileId,
                    )
                  }
                  options={billingProfileOptions}
                  isDisabled={
                    wellSites?.length === 0 || editTheBillingProfileDisabled
                  }
                  required
                  testSelector="jobs_add_billing-profile_select"
                />
              </div>
              {operationType === 12 && (
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="number"
                  onChange={handlers.valueChange('loadWeight')}
                  value={formData.loadWeight}
                  label="Load volume, bbl"
                  min="1"
                  max="60000"
                  testSelector="jobs_edit_water-job_load-volume_input"
                />
              </div>
              )}
              <div className="form-group has-feedback col-md-6">
                <Textarea
                  value={formData.directions}
                  onChange={handlers.valueChange('directions')}
                  label="Directions"
                  testSelector="jobs_edit_directions_input"
                  required
                />
              </div>
              {operationType !== 12 && (
                <div className="form-group has-feedback col-md-6">
                  <Select
                    closeMenuOnSelect={false}
                    onChange={e =>
                      handlers.valueChange('stagingSite')(
                        e && e.value ? e.value : null,
                      )
                    }
                    value={
                      formData.stagingSite &&
                      stagingSiteOptions.find(
                        k => k.value === formData.stagingSite,
                      )
                    }
                    options={stagingSiteOptions}
                    placeholder="Staging Site"
                    testSelector="jobs_edit_staging-site_select"
                    isClearable
                  />
                </div>
              )}
              <div className="form-group has-feedback col-md-6">
                <Select
                  closeMenuOnSelect={false}
                  onChange={e =>
                    handlers.valueChange('wellSiteId')(
                      e && e.value ? e.value : null,
                    )
                  }
                  value={
                    formData.wellSiteId &&
                    wellsiteList.find(k => k.value === formData.wellSiteId)
                  }
                  options={wellsiteList}
                  placeholder="Job Location"
                  required
                  testSelector="jobs_edit_well-site_select"
                  isClearable
                />
              </div>
              {operationType !== 12 && (
                <div className="form-group has-feedback col-md-6">
                  <Input
                    type="number"
                    onChange={handlers.valueChange('totalWeightPerJob')}
                    value={formData.totalWeightPerJob}
                    label="Total Quantity Per Job"
                    max="2000000000"
                    testSelector="jobs_edit_total-weight-per-job_input"
                  />
                </div>
              )}
               {operationType !== 12 && (
                <div className="form-group has-feedback col-md-6">
                  <Input
                    type="number"
                    onChange={handlers.valueChange('targetStagesPerDay')}
                    value={formData.targetStagesPerDay}
                    label="Target Stages Per Day"
                    testSelector="jobs_edit_target-stages-per-day_input"
                  />
                </div>
               )}
              <div className="form-group has-feedback col-md-6">
                {authorize({ access: ['JOB_MANAGER', 'CUSTOMER'] }) && (
                  <Select
                    isMulti
                    isDisabled={isAdditionalOperatorsDisabled}
                    closeMenuOnSelect={false}
                    onChange={handlers.valueChange('additionalOperators')}
                    options={operatorOptions}
                    placeholder="Additional Operators"
                    testSelector="jobs_add_operators_select"
                    value={formData.additionalOperators}
                  />
                )}
              </div>
              <div className="form-group has-feedback col-md-6">
                <Select
                  placeholder="Tags"
                  isMulti
                  closeMenuOnSelect={false}
                  onChange={handlers.valueChange('tags')}
                  options={tagOptions}
                  value={formData.tags}
                  testSelector="jobs_edit_job-details_tags_input"
                />
              </div>
              {operationType !== 12 && (
                <JobDesign
                  jobDesign={formData.jobDesign}
                  handlers={handlers}
                  customerCommodities={customerCommodities}
                />
              )}
              {operationType !== 12 && (
                <StorageConfig
                  storageConfig={formData.storageConfig}
                  jobDesign={formData.jobDesign}
                  handlers={handlers}
                  customerId={jobData.customerId}
                />
              )}
              {/* <div className="form-group has-feedback col-md-6">
                  <Input
                    type="checkbox"
                    label="Send completion code to driver"
                    testSelector="jobs-list_edit_send-code-to-driver_input"
                    isChecked={sendCompletionCodeToDriver}
                    onChange={e =>
                      setModalValue(e, 'sendCompletionCodeToDriver')
                    }
                  />
                </div> */}
              {operationType !== 12 && (
                <div className="form-group has-feedback col-md-6">
                  <Input
                    type="checkbox"
                    label="Not skip appt time"
                    isChecked={Boolean(formData.assignClosesOnly)}
                    testSelector="jobs-list_edit_assign-close-only_input"
                    onChange={handlers.valueChange('assignClosesOnly')}
                  />
                </div>
              )}
              {operationType !== 12 && (
                <div className="form-group has-feedback col-md-6">
                  <Input
                    type="checkbox"
                    label="Use Commodity Price"
                    testSelector="jobs-list_edit_use-commodity-price_input"
                    isChecked={formData.useCommodityPrice}
                    onChange={handlers.valueChange('useCommodityPrice')}
                  />
                </div>
              )}
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="checkbox"
                  label="Enable alerts"
                  testSelector="jobs-list_edit_alerts-enable_input"
                  isChecked={Boolean(formData.alertsEnabled)}
                  onChange={handlers.valueChange('alertsEnabled')}
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="checkbox"
                  testSelector="jobs-list_edit-job_alerts_enable-sms_input"
                  isChecked={Boolean(formData.messagesAllowed)}
                  onChange={handlers.valueChange('messagesAllowed')}
                  label="Enable SMS alerts"
                />
              </div>
              <Authorize
                abilityPermissions={[
                  {
                    resource: 'Admin',
                    permissions: ['update'],
                  },
                ]}>
                <div className="form-group has-feedback col-md-6">
                  <Input
                    type="checkbox"
                    testSelector="jobs-list_edit-test-job_input"
                    isChecked={Boolean(formData.isTestJob)}
                    onChange={handlers.valueChange('isTestJob')}
                    label="Enable Test Job"
                  />
                </div>
              </Authorize>
              {Boolean(formData.messagesAllowed) && (
                <div className="form-group has-feedback col-md-6">
                  {(formData.phoneNumbers.length
                    ? formData.phoneNumbers
                    : [{ code: '+1' }]
                  ).map((item, index) => (
                    <div className="no-outer-gutter" key={`phone-${index}`}>
                      <div
                        className="form-group has-feedback col-md-12"
                        style={{ padding: 0 }}>
                        <Input
                          onChange={handlers.arrayValueChange(
                            index,
                            'number',
                            'phoneNumbers',
                          )}
                          value={item.number || ''}
                          label="Phone Number"
                          type="tel"
                          required
                          testSelector="jobs_add_number_input"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {Boolean(formData.messagesAllowed) && (
                <>
                  <div className="form-group has-feedback col-md-6">
                    <Input
                      type="number"
                      onChange={handlers.valueChange('minDriversAmount')}
                      value={formData.minDriversAmount}
                      label="Notify when active drivers count drops below"
                      min="0"
                      required
                      testSelector="jobs_add-job_alerts_notify-when-driver_input"
                    />
                  </div>
                  <div className="form-group has-feedback col-md-6">
                    <Input
                      type="number"
                      onChange={handlers.valueChange('demurrageAlert')}
                      value={formData.demurrageAlert}
                      label="Notify when demurrage reaches (hours)"
                      min="0"
                      required
                      testSelector="jobs_add-job_alerts_notify-when-demurrage_input"
                    />
                  </div>
                </>
              )}
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleClose}
            colour="white"
            testSelector="jobs-list_edit-modal_close_btn">
            Close
          </Button>
          {Boolean(isDraftJob) && Boolean(formData.loaded) && (
            <Button
              disabled={isDateAlert}
              type="submit"
              testSelector="save-draft">
              Save Draft
            </Button>
          )}
          {!isLoading && !isWaterJob && (
            <Button
              type="submit"
              disabled={!isValid || isDateAlert || isPending}
              testSelector="jobs-list_edit-modal_save_btn">
              Save
            </Button>
          )}
          {!isLoading && isWaterJob && (
            <Button
              type="submit"
              disabled={isDateAlert || isPending}
              testSelector="jobs-list_edit-modal_save_btn">
              Save
            </Button>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};
export default EditJob;
