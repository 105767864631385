import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import authService from 'services/auth/service';
import { ROLES } from 'config/constants';

export const HomeStateContext = createContext({});

const HomeStateProvider = ({ children }) => {
  const [map, setMap] = useState(null);
  const [onlyAlerts, setOnlyAlerts] = useState(false);

  useEffect(() => {
    const user = authService.getUser();
    if (user.role === ROLES.CARRIER || user.role === ROLES.CUSTOMER) {
      setOnlyAlerts(false);
    }
  }, []);

  return (
    <HomeStateContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ map, setMap, onlyAlerts, setOnlyAlerts }}>
      {children}
    </HomeStateContext.Provider>
  );
};

HomeStateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HomeStateProvider;
