const openAddModal = () => ({
  type: 'ADD_UNIT_SET_INPUT_VALUE',
  name: 'showAddModal',
  value: true,
});

export default {
  openAddModal,
}

/*
//old file
import swal from 'bootstrap-sweetalert';
import { po } from 'services/po/service';
import { order } from 'services/order/service';
import { sandSite } from 'services/sandSite/service';
import initActions from './JobDetails';

const openAddModal = () => ({
  type: 'ADD_UNITR_SET_INPUT_VALUE',
  name: 'showAddModal',
  value: true,
});
const closeAddModal = () => ({
  type: 'ADD_UNITR_SET_INPUT_VALUE',
  name: 'showAddModal',
  value: false,
});

const openUpdateModal = data => dispatch => {
  const save = data => ({ type: 'UPDATE_UNITR_SET_INIT_VALUE', data });
  dispatch(
    save({
      showUpdateModal: true,
      ...data,
      commodityUnits: data.units,
      dollarsPerTon: data.price,
      sandSiteId: data.id,
      enabled: !!data.isEnabled,
    }),
  );
};

const initTurnModal = (info, districtList) => dispatch => {
  districtList.forEach(district => {
    if (info.districts.length) {
      info.districts.map(item => {
        if (item.id === district.id) {
          district.isEnabled = item.isEnabled;
        }
      });
    }
    if (district.isEnabled === undefined || !info.districts.length) {
      district.isEnabled = true;
    }
  });

  const save = (data, districts) => ({
    type: 'UNIT_INFO_INIT',
    data,
    districts,
  });
  dispatch(save(info, districtList));
};

const closeUpdateModal = () => ({
  type: 'UPDATE_UNITR_SET_INPUT_VALUE',
  name: 'showUpdateModal',
  value: false,
});

// Add unit modal actions
const setInputValue = (value, name) => ({
  type: 'ADD_UNITR_SET_INPUT_VALUE',
  value,
  name,
});

const submitAdd = closeModal => (dispatch, getState) => {
  const state = getState();

  const {
    jobDetails: {
      details: {
        details: { customerId, jobId },
      },
      unitModals: { add },
    },
  } = state;

  const randomHash = String(new Date().getTime()).slice(-8);

  const data = {
    ...add,
    jobId,
    customerId,
    poNo: `${jobId}_${add.sandSiteId}_${randomHash}`,
  };

  po.add(data)
    .then(msg => {
      swal('Success!', '', 'success');
      closeModal();
      dispatch(initActions.getDetails(data.jobId));
    })
    .catch(error => swal(error.data.message, '', 'error'));
};

const clear = () => ({ type: 'ADD_UNIT_CLEAR' });

// update unit modal actions
const setInputValueUpdate = (value, name) => ({
  type: 'UPDATE_UNITR_SET_INPUT_VALUE',
  value,
  name,
});

const submitUpdate = closeModal => (dispatch, getState) => {
  const {
    jobDetails: {
      details: {
        details: { jobId },
      },
      unitModals: {
        update: { sandSiteId, enabled },
      },
    },
  } = getState();
  dispatch(setInputValueUpdate(true, 'loading'));
  if (!enabled) {
    order
      .get({ statuses: [1, 2, 5, 3], sandSiteId, jobId })
      .then(msg => {
        const filteredOrders = msg.data.data.orders.filter(item =>
          Boolean(item.driver_id),
        );
        dispatch(setInputValueUpdate(false, 'loading'));
        if (filteredOrders.length) {
          dispatch(setInputValueUpdate(filteredOrders, 'filteredOrders'));
          dispatch(turnPreview());
        } else {
          dispatch(update(closeModal));
        }
      })
      .catch(error => dispatch(setInputValueUpdate(false, 'loading')));
  } else {
    dispatch(update(closeModal));
  }
};

const update = closeModal => (dispatch, getState) => {
  const {
    jobDetails: {
      details: {
        details: { jobId },
      },
      unitModals: {
        update: {
          approvedMileage,
          enabled,
          commodityUnits,
          dollarsPerTon,
          poNo,
          priority,
          sandSiteId,
          sandTypeId,
          sandVendorId,
          travelTimeMinutes,
          units,
        },
      },
    },
  } = getState();
  const data = {
    approvedMileage,
    commodityUnits,
    dollarsPerTon,
    poNo,
    priority,
    sandSiteId,
    sandTypeId,
    sandVendorId,
    travelTimeMinutes,
    units,
    netAmount: 1000000000,
    poReference: '-',
    enabled: Number(enabled),
    jobId,
  };
  po.update(data)
    .then(msg => {
      swal('Success!', '', 'success');
      closeModal();
      dispatch(initActions.getDetails(jobId));
    })
    .catch(error => swal(error.data.message, '', 'error'));
};

const clearUpdate = () => ({ type: 'UPDATE_UNIT_CLEAR' });

// info modal actions
const clearInfo = () => ({ type: 'UNIT_INFO_CLEAR' });

const setInputValueInfo = (value, name) => ({
  type: 'UNIT_INFO_SET_INPUT_VALUE',
  value,
  name,
});

const setDistrictsValue = (value, index, name) => (dispatch, getState) => {
  const state = getState();
  const array = state.jobManager.jobDetails.unitInfo.jobDistricts;
  array[index][name] = value;
  dispatch(setInputValueInfo(array, 'jobDistricts'));
};

const updateInfo = closeModal => async (dispatch, getState) => {
  const {
    jobManager: {
      jobDetails: {
        details: {
          details: { jobId },
        },
        unitInfo,
      },
    },
  } = getState();
  const { isEnabled, jobDistricts, id } = unitInfo;

  const success = () => {
    swal('Success!', '', 'success');
    closeModal();
    dispatch(initActions.getDetails(jobId));
    dispatch(clearInfo());
  };

  const data = {
    ...unitInfo,
    netAmount: 1000000000,
    poReference: '-',
    enabled: Number(isEnabled),
    jobId,
    sandSiteId: id,
  };
  const districts = [];
  jobDistricts.map(item =>
    districts.push({
      id: item.id,
      isEnabled: Boolean(item.isEnabled),
    }),
  );

  try {
    await po.update(data);
    await sandSite.district.update({ sandSiteId: id, params: districts });
    success();
  } catch (e) {
    swal(e.data.message, '', 'error');
  }
};

const turnPreview = () => ({ type: 'UNIT_INFO_TURN_PREVIEW' });

const getFilteredOrders = (
  sandSiteId,
  param,
  paramName,
  closeModal,
) => dispatch => {
  order
    .get({ statuses: [1, 2, 5, 3], sandSiteId, [paramName]: param })
    .then(msg => {
      const filteredOrders = msg.data.data.orders.filter(item =>
        Boolean(item.driver_id),
      );
      dispatch(setInputValueInfo(false, 'loading'));
      if (filteredOrders.length) {
        dispatch(setInputValueInfo(filteredOrders, 'filteredOrders'));
        dispatch(turnPreview());
      } else {
        dispatch(updateInfo(closeModal));
      }
    })
    .catch(error => dispatch(setInputValueInfo(false, 'loading')));
};

const saveChanges = closeModal => (dispatch, getState) => {
  const {
    jobManager: {
      jobDetails: {
        details: {
          details: { jobId },
        },
        unitInfo: { id, isEnabled, jobDistricts },
      },
    },
  } = getState();
  dispatch(setInputValueInfo(true, 'loading'));
  const disabledDistricts = jobDistricts.filter(distr => !distr.isEnabled);
  if (disabledDistricts.length) {
    const districtIds = [];
    disabledDistricts.map(item => districtIds.push(item.id));
    dispatch(getFilteredOrders(id, districtIds, 'districtIds', closeModal));
  } else if (!isEnabled) {
    dispatch(getFilteredOrders(id, jobId, 'jobId', closeModal));
  } else {
    dispatch(updateInfo(closeModal));
  }
};

const reroute = closeModal => (dispatch, getState) => {
  const state = getState();
  const unitInfo = { ...state.jobManager.jobDetails.unitInfo };
  const { filteredOrders } = unitInfo;
  const orders = [...filteredOrders];
  const { jobId } = state.jobManager.jobDetails.details.details;
  orders.forEach((item, index) => {
    order
      .reroute(item.order_id, { jobId })
      .then(msg => {
        const itemIndex = orders.findIndex(
          order => order.order_id == item.order_id,
        );
        orders.splice(itemIndex, 1);
        dispatch(setInputValueInfo(orders, 'filteredOrders'));
        if (!orders.length) {
          dispatch(updateInfo(closeModal));
        }
      })
      .catch(error => {
        item.errorReroute = error.data.message;
        dispatch(setInputValueInfo(filteredOrders, 'filteredOrders'));
      });
  });
};

export default {
  openAddModal,
  closeAddModal,
  openUpdateModal,
  closeUpdateModal,
  initTurnModal,

  //add modal actions
  setInputValue,
  submitAdd,
  clear,

  // update modal actions
  setInputValueUpdate,
  submitUpdate,
  clearUpdate,

  // info modal actions
  setInputValueInfo,
  setDistrictsValue,
  saveChanges,
  reroute,
  turnPreview,
  clearInfo,
};

*/
