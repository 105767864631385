import moment from 'moment';
import swal from 'bootstrap-sweetalert';
import { ROLES } from 'config/constants';
import { convertTime } from 'utils/convertTime';

export const MAX_SITE_TIME = 240;
export const MIN_SITE_TIME = 5;

export const formattedDate = date =>
  moment(date, ['YYYY-MM-DD hh:mm A', 'MM-DD-YYYY hh:mm A', 'D MMM YYYY H:mm']);

export const formattedPos = (pos, operationType) =>
  pos.map(item => {
    item.label =
      operationType !== 12
        ? `${item.poNo} | ${item.sandTypeName} | ${item.originName}`
        : `${item.destinationName}`;
    item.value = item.poNo;
    item.disabled = !item.enabled;
    return item;
  });

/**
 *
 * @param {import('./hooks').Modify} data
 * @returns
 */
export const isValidData = (data, jobData) => {
  if (!data || !jobData) {
    return false;
  }
  if (
    data.stage && jobData?.stagingSite &&
    data.stageArrivalTime &&
    data.stageArrivalTime !== 'Invalid date' &&
    (data.stageDepartureTime === 'Invalid date' || !data.stageDepartureTime) &&
    (data.shouldComplete || data.orderStatus === 4)
  ) {
    swal('Please, select a Stage Departure Time', '', 'error');
    return false;
  }

  if (
    data.stage && jobData?.stagingSite &&
    data.stageDepartureTime &&
    data.stageDepartureTime !== 'Invalid date' &&
    (data.stageArrivalTime === 'Invalid date' || !data.stageArrivalTime)
  ) {
    swal('Please, select a Stage Arrival Time', '', 'error');
    return false;
  }

  const validationDate = (date1, date2) => {
    const dat1 = Date.parse(date1);
    const dat2 = Date.parse(date2);
    return !(dat2 < dat1);
  };

  const sandSiteTime = moment(data.loadDepartureTime).diff(
    moment(data.loadArrivalTime),
    'minutes',
  );
  const wellsiteTime = moment(data.wellDepartureTime).diff(
    moment(data.wellArrivalTime),
    'minutes',
  );

  if (
    data.orderAcceptedAt !== 'Invalid date' &&
    !validationDate(
      moment(data.createdAt)
        .utc()
        .format('YYYY/MM/DD hh:mm A'),
      moment(data.orderAcceptedAt).format('YYYY/MM/DD hh:mm A'),
    )
  ) {
    swal(
      `Order Accepted Time must be later than Order Created (${moment(
        data.createdAt,
      ).format('YYYY-MM-DD hh:mm A')})`,
      '',
      'error',
    );
    return false;
  }

  if (
    data.orderAcceptedAt !== 'Invalid date' &&
    data.loadArrivalTime !== 'Invalid date' &&
    !validationDate(data.orderAcceptedAt, data.loadArrivalTime)
  ) {
    swal(
      'Origin Arrival Time must be later than Order Accepted Time',
      '',
      'error',
    );
    return false;
  }

  if (
    data.loadArrivalTime !== 'Invalid date' &&
    data.loadDepartureTime !== 'Invalid date' &&
    !validationDate(data.loadArrivalTime, data.loadDepartureTime)
  ) {
    swal(
      'Origin Departure Time must be later than Origin Arrival Time',
      '',
      'error',
    );
    return false;
  }

  if (
    data.isStageSiteOnOrder &&
    data.loadDepartureTime !== 'Invalid date' &&
    data.stageArrivalTime &&
    data.stageArrivalTime !== 'Invalid date' &&
    !validationDate(data.loadDepartureTime, data.stageArrivalTime)
  ) {
    swal(
      'Stage Arrival Time must be later than Origin Departure Time',
      '',
      'error',
    );
    return false;
  }

  if (
    data.isStageSiteOnOrder &&
    data.stageArrivalTime &&
    data.stageArrivalTime !== 'Invalid date' &&
    data.stageDepartureTime &&
    data.stageDepartureTime !== 'Invalid date' &&
    !validationDate(data.stageArrivalTime, data.stageDepartureTime)
  ) {
    swal(
      'Stage Departure time must be later than Stage Arrival Time',
      '',
      'error',
    );
    return false;
  }

  if (
    data.loadDepartureTime !== 'Invalid date' &&
    data.wellArrivalTime !== 'Invalid date' &&
    !validationDate(data.loadDepartureTime, data.wellArrivalTime)
  ) {
    swal(
      'Destination Arrival Time must be later than Origin Departure Time',
      '',
      'error',
    );
    return false;
  }

  if (
    data.isStageSiteOnOrder &&
    data.stageArrivalTime &&
    data.stageArrivalTime !== 'Invalid date' &&
    (!data.stageDepartureTime ||
      (data.stageDepartureTime &&
        data.stageDepartureTime === 'Invalid date')) &&
    data.wellArrivalTime !== 'Invalid date' &&
    !validationDate(data.stageArrivalTime, data.wellArrivalTime)
  ) {
    swal(
      'Destination Arrival Time must be later than Stage Arrival Time',
      '',
      'error',
    );
    return false;
  }

  if (
    data.isStageSiteOnOrder &&
    data.stageDepartureTime &&
    data.stageDepartureTime !== 'Invalid date' &&
    data.wellArrivalTime !== 'Invalid date' &&
    !validationDate(data.stageDepartureTime, data.wellArrivalTime)
  ) {
    swal(
      'Destination Arrival Time must be later than Stage Departure Time',
      '',
      'error',
    );
    return false;
  }

  if (
    data.wellArrivalTime !== 'Invalid date' &&
    data.wellDepartureTime !== 'Invalid date' &&
    !validationDate(data.wellArrivalTime, data.wellDepartureTime)
  ) {
    swal(
      'Destination Departure time must be later than Destination Arrival Time',
      '',
      'error',
    );
    return false;
  }

  if (data.loadDepartureTime && data.loadArrivalTime && sandSiteTime < MIN_SITE_TIME) {
    swal(`Time at origin must be at least ${MIN_SITE_TIME} minutes`, '', 'error');
    return false;
  }

  if (data.wellDepartureTime && data.wellArrivalTime && wellsiteTime < MIN_SITE_TIME) {
    swal(`Time at destination must be at least ${MIN_SITE_TIME} minutes`, '', 'error');
    return false;
  }

  return true;
};

export const isLoadWeightValid = (weight, jobWeight) => {
  if (weight && (weight > jobWeight || weight < 0)) {
    return true;
  }
  return false;
};

export const formatOrderInfo = order => {
  const data = {
    orderId: order.order_id,
    mileage: order.mileage,
    customerOrderId: order.customer_order_id,
    sandTicketNo: order.sand_ticket_no,
    orderAcceptedAt: convertTime(order.order_accepted_at),
    loadArrivalTime: convertTime(order.load_arrival),
    loadDepartureTime: convertTime(order.load_depart),
    wellArrivalTime: convertTime(order.well_arrival),
    wellDepartureTime: convertTime(order.well_depart),
    driverWeight: order.driver_weight,
    stage: order.stage,
    po: order.po,
    truck: order.truck,
    trailerNumber: order.trailer_number,
    truckDown: order.truck_status === 1,
    isStageSiteOnOrder: order.staging_site && order.staging_stop_type,
    orderStatus: Number(order.status),
    kingComp: order.king_comp,
    createdAt: order.created_at,
    completedAt: order.completed_at,
    updatedAt: order.updated_at,
    shouldComplete: false,
    // Notification info
    chatId: order.chat_id,
    driverId: order.driver_id,
  };

  if (order.enter_stage) {
    data.stageArrivalTime = convertTime(order.enter_stage);
  }
  if (order.depart_stage) {
    data.stageDepartureTime = convertTime(order.depart_stage);
  }

  return data;
};

/**
 *
 * @param {import('./hooks').Modify} data
 * @returns
 */
export const formatModifyData = data => {
  const formattedData = {
    ...data,
    lastUpdatedAt: data.updatedAt,
  };

  const timesValue = [
    'orderAcceptedAt',
    'loadArrivalTime',
    'loadDepartureTime',
    'wellArrivalTime',
    'wellDepartureTime',
  ];
  if (data.stageArrivalTime && data.stageArrivalTime !== 'Invalid date') {
    timesValue.push('stageArrivalTime');
  } else {
    delete formattedData.stageArrivalTime;
  }

  if (data.stageDepartureTime && data.stageDepartureTime !== 'Invalid date') {
    timesValue.push('stageDepartureTime');
  } else {
    delete formattedData.stageDepartureTime;
  }

  for (let i = 0; i < timesValue.length; i += 1) {
    formattedData[timesValue[i]] = moment(data[timesValue[i]], [
      'YYYY-MM-DD hh:mm A',
      'MM-DD-YYYY hh:mm A',
      'D MMM YYYY H:mm',
    ])
      .utc()
      .format('YYYY/MM/DD hh:mm A');
    if (
      data.shouldComplete &&
      (!data[timesValue[i]] || data[timesValue[i]] === 'Invalid date')
    ) {
      swal('Invalid date', '', 'error');
      return null;
    }
  }
  formattedData.sandTicketNo = String(data.sandTicketNo);

  // Convert from boolean to number
  formattedData.truckDown = data.truckDown ? 1 : 0;

  Object.keys(formattedData).forEach(item => {
    if (data[item] === '' || data[item] === 'Invalid date') {
      delete formattedData[item];
    }
  });

  formattedData.status = data.orderStatus;
  formattedData.mileage = Math.floor(data.mileage).toString();

  return formattedData;
};

export const getMaxLoadWeight = (user, order) => {
  if (
    order?.accessLevel === 'owner' ||
    order?.accessLevel === 'shared' ||
    order?.accessLevel === 'shared_creator' ||
    user.role === ROLES.JOB_MANAGER
  ) {
    return 100000;
  } 
    return order?.maxLoadWeight ?? 85000;
  
};
